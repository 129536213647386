import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Page not found</h1>
    <p>Sorry, you hit a route that doesn't exist, please go to the{" "} 
      <Link to="/" style={{ textDecoration: "none" }}>
        home page
      </Link> to browse the site.</p>

    
  </Layout>
)

export default NotFoundPage
